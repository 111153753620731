import { logout } from 'src/hocs/AuthProvider';

import { useSelector } from 'src/store';
import {
  isLoggedInSelector,
  authUserNameSelector,
  authRoleReadableNameSelector,
} from 'src/store/selectors/authSelector';

import { LogoutIconSVG, UserIcon } from '@itm/shared-frontend/lib/components/icons';

function Header() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userName = useSelector(authUserNameSelector);
  const authRoleReadableName = useSelector(authRoleReadableNameSelector);

  return (
    <header className="has-background-white">
      <nav className="navbar is-flex is-justify-content-flex-end" role="navigation" aria-label="main navigation">
        {isLoggedIn && (
          <div className="navbar-brand">
            <div className="navbar-item dropdown is-hoverable is-right mx-5">
              <div className="dropdown-trigger">
                <a
                  className="navbar-link has-icon is-arrowless has-text-grey-light px-2"
                  role="button"
                  aria-label={userName || 'User name'}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  tabIndex={0}
                >
                  <UserIcon />
                  <div className="dropdown-item navbar-item is-flex-direction-column is-align-items-start is-hidden-mobile pl-5">
                    <p>{userName}</p>
                    <p className="has-text-grey">{authRoleReadableName}</p>
                  </div>
                </a>
                <div className="dropdown-menu is-right is-hidden-tablet" id="dropdown-menu" role="menu">
                  <div className="dropdown-content pb-0">
                    <div className="dropdown-item navbar-item is-flex-direction-column is-align-items-start pl-5">
                      <p>{userName}</p>
                      <p className="has-text-grey">{authRoleReadableName}</p>
                    </div>
                    <hr className="dropdown-divider mb-0" />
                    <button
                      className="button logout-button has-icon dropdown-item is-justify-content-flex-start pl-5"
                      type="button"
                      onClick={() => logout()}
                      role="menuitem"
                    >
                      <LogoutIconSVG /> <span className="pl-3">Log out</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-item is-hoverable is-right is-hidden-mobile mr-5">
              <button
                className="button logout-button has-icon px-4"
                type="button"
                onClick={() => logout()}
                aria-label="Logout"
              >
                <LogoutIconSVG />
              </button>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
